<template>
  <div class="manage-account animatedBox">
    <div class="container fluid">
      <div class="card p-3">
        <h3 class="mainTitle mb-4">Manage Account</h3>
        <div class="main-contents">
          <div class="row mb-2 mxn-1">
            <!-- Personal Information -->
            <div class="col-12 md-col-6">
              <div class="p-card p-2 mb-2 mx-0 md-mx-1">
                <h4 class="mb-2">Personal Details</h4>
                <div class="col-12 row align-items-start">
                  <div class="col-12 md-col-4 text-center">
                    <img
                      v-if="accountInfo.avatar.length > 0"
                      :src="$getFileURL(accountInfo.avatar[0])"
                      :alt="accountInfo.avatar[0].fileName"
                      class="avatar"
                      loading="lazy"
                      @error="$setAltPhoto"
                    />
                  </div>

                  <div class="col-12 md-col-8 md-pl-2">
                    <!-- Name and verification -->
                    <p class="text-title d-flex align-items-center px-1">
                      {{ accountInfo.name }}
                      <span
                        v-if="accountInfo.isCertified"
                        class="d-inline-block"
                      >
                        <div class="certified ml-1">
                          <i class="fas fa-check"></i>
                        </div>
                      </span>
                    </p>
                    <p class="text-context px-1">
                      {{ accountInfo.actualName }}
                    </p>
                    <chip class="my-1">
                      {{ accountInfo.rolePermission.name }}
                    </chip>

                    <!-- Contacts and personal data -->
                    <div class="mt-1">
                      <p class="data px-1">
                        <i class="fas fa-map-marker-alt mr-1"></i>
                        {{ $toTitleCase(accountInfo.address) }},
                        {{ $toTitleCase(accountInfo.area.name) }},
                        {{ $toTitleCase(accountInfo.city.name) }},
                        {{ $toTitleCase(accountInfo.state.name) }},
                        {{ $toTitleCase(accountInfo.country.name) }}
                      </p>
                      <p class="data px-1">
                        <i class="fas fa-envelope mr-1"></i>
                        {{ accountInfo.user.email }}
                      </p>
                      <p class="data px-1">
                        <i class="fas fa-phone mr-1"></i>
                        {{ $getUserPhoneNumber(accountInfo.user) }}
                      </p>
                      <p class="data px-1">
                        <i class="fas fa-venus-mars mr-1"></i>
                        {{ accountInfo.user.gender }}
                      </p>
                      <p class="data px-1">
                        <i class="fas fa-birthday-cake mr-1"></i>
                        {{ $moment(accountInfo.user.birthdate).format("ll") }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Bank Details -->
            <div class="col-12 md-col-6">
              <div class="p-card p-2 mb-2 mx-0 md-mx-1">
                <h4 class="mb-2">Bank Details</h4>
                <div class="row">
                  <p class="col-5">Bank</p>
                  <p class="col-7">{{ accountInfo.bank }}</p>
                  <p class="col-5">Account No</p>
                  <p class="col-7">{{ accountInfo.bankAccountNumber }}</p>
                  <p class="col-5">Account Name</p>
                  <p class="col-7">{{ accountInfo.bankAccountFullName }}</p>
                </div>
              </div>
            </div>
          </div>

          <!-- Intro & Experience -->
          <fd-form class="col-12" @submit="submitForm">
            <div class="card p-2 mb-3">
              <fd-textarea
                v-model="accountData.introduction"
                class="col-12 px-1 mb-2"
                label="Introduction"
                name="introduction"
              >
              </fd-textarea>
              <fd-textarea
                v-model="accountData.experience"
                class="col-12 px-1 mb-2"
                label="Experience"
                name="experience"
              >
              </fd-textarea>
              <div class="mt-4 text-right">
                <button type="submit" class="btn main">Update</button>
              </div>
            </div>
          </fd-form>

          <!-- Change Password -->
          <div class="col-12">
            <change-password
              ref="changePassword"
              @update="updatePassword"
            ></change-password>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  required,
  minLength,
  email,
  phone,
  password
} from "@/components/GlobalComponents/FormComponents/Validator/rules";

import genderEnum from "@/enums/gender";

import AccountModel from "@/models/account";

export default {
  name: "manage-account",
  components: {
    ChangePassword: () => import("./ChangePassword"),
    Chip: () => import("@/components/GlobalComponents/Chip")
  },
  mixins: [],
  data() {
    return {
      genderOptions: this.$mapJsonToArray(genderEnum, (e) => {
        return {
          id: e,
          name: e
        };
      }),

      accountData: {
        introduction: "",
        experience: ""
      },

      accountInfo: {
        avatar: [],
        name: "",
        actualName: "",
        email: "",
        contact: "",
        birthdate: "",
        gender: "",
        isCertified: false,

        bank: "",
        bankAccountNumber: "",
        bankAccountFullName: "",

        addresses: "",
        country: {},
        state: {},
        city: {},
        area: {}
      },

      validators: {
        required: required,
        min: minLength,
        email: email,
        password: password,
        phone: phone
      }
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    async initData() {
      this.$store.commit("setIsLoading", true);
      await this.getAccountData();
      this.$store.commit("setIsLoading", false);
    },
    // ============================== API Related ==============================
    async getAccountData() {
      try {
        let data = await this.$store.dispatch("manageAccount/getAccount");
        this.accountInfo = data;
        this.accountData = AccountModel.getToEditResponse(data);
      } catch (error) {
        throw error;
      }
    },
    validateError() {
      this.$notify({
        group: "alert",
        type: "error",
        title: "Error",
        text: "Validation failed, please check if all the inputs are valid."
      });
    },
    async updatePassword(password) {
      this.$store.commit("setIsLoading", true);
      try {
        await this.$store.dispatch("manageAccount/updatePassword", password);

        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Password updated successfully."
        });

        this.$refs.changePassword.clearInputs();

        this.$store.commit("setIsLoading", false);
        return;
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: `An error occured. ${error.response.data.message}`
        });
        throw error;
      }
    },
    async submitForm() {
      try {
        this.$store.commit("setIsLoading", true);
        let response = await this.$store.dispatch(
          "manageAccount/updateAccount",
          AccountModel.postPayload(this.accountData)
        );
        this.$store.commit("setIsLoading", false);

        this.$auth.user(response);

        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Account has been updated successfully."
        });
        return response;
      } catch (error) {
        this.$store.commit("setIsLoading", false);

        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Something went wrong, please try again."
        });
        throw error;
      }
    }
  }
};
</script>

<style lang="scss">
.manage-account {
  form {
    @extend %formDesign;
  }
  .p-card {
    border-radius: 4px;
    box-shadow: 0px 4px 8px -1px #00000030;
  }
  .avatar {
    @include image(cover);
    width: 150px;
    height: 150px;
    border-radius: 50%;
    display: inline-block;
  }
  .certified {
    background: $color-info;
    color: white;
    border-radius: 1000px;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      font-size: 8px;
    }
  }
  .text-title {
    font-size: 20px;
  }
  .text-context {
    color: #707070;
  }
}
</style>
